import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ResellerCloverConfigurationModel, ResellerCloverConfigurationSearchModel } from '../../models/reseller-clover-configuration.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';

@Component({
  selector: 'app-list-resellers',
  templateUrl: './list-resellers.component.html',
  styleUrls: ['./list-resellers.component.scss']
})
export class ListResellersComponent implements OnInit {

  displayedColumns =
  ['id', 'instituicao', 'serviceContract', 'tipoCanal', 'canal', 'resellerClover','options_edit'];

  defaultSizeItems = 10;
  totalPages: number;
  itemsNotFoundByFilter = false;

  @Input() totalItens;

  @Input() dataSource: MatTableDataSource<ResellerCloverConfigurationModel>;
  originalDataSource = new MatTableDataSource<ResellerCloverConfigurationModel>();

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() onSearch = new EventEmitter<void>();
  @Output() enableItem = new EventEmitter<ResellerCloverConfigurationModel>();
  @Output() itemDetails = new EventEmitter<ResellerCloverConfigurationModel>();
  @Output() editItem = new EventEmitter<ResellerCloverConfigurationSearchModel>();

  @ViewChild('binding') binding: ElementRef;
  expandedElement: ResellerCloverConfigurationModel;

  icons: { [key:string]: IconDefinition }

  constructor(private authService: AuthService) { }

  ngOnInit(): void {    
    this.dataSource.connect().subscribe(response => {
      if(response && response.length){
        this.originalDataSource.data = this.dataSource.data;
      }
    })
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  edit(resellerCloverConfig: ResellerCloverConfigurationSearchModel) {
    this.editItem.emit(resellerCloverConfig);
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.RESELLER_CLOVER_CONFIGURATION_WRITE]);
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

}
