import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApiStatusComponent } from './api-status/api-status.component';
import { BwServiceContractConfigComponent } from './bw-service-contract-config/bw-service-contract-config.component';
import { CnaeComplianceRegisterComponent } from './cnae-compliance-register/cnae-compliance-register.component';
import { CnaeCreditRiskRegisterComponent } from './cnae-credit-risk-register/cnae-credit-risk-register.component';
import { ComplianceProposalsComponent } from './compliance-proposal-list/compliance-proposals.component';
import { ConciliatorComponent } from './conciliator/conciliator.component';
import { ConfigAppComponent } from './config-app/config-app.component';
import { ConfigurationChecklistComponent } from './configuration-checklist/configuration-checklist.component';
import { ConfigurationProfessionalLicenseComponent } from './configuration-professional-license/configuration-professional-license/configuration-professional-license.component';
import { ConfigurationSetupComponent } from './configuration-setup/configuration-setup.component';
import { CoreComponent } from './core/core.component';
import { CreditRiskProposalsComponent } from './credit-risk-proposals/credit-risk-proposals.component';
import { EcommerceCnaeWhitelistComponent } from './ecommerce-cnae-whitelist/ecommerce-cnae-whitelist.component';
import { EconomicalGroupComponent } from './economical-group/economical-group.component';
import { FAQCategoryComponent } from './faq-category/faq-category.component';
import { FaqConfigurationComponent } from './faq-configuration/faq-configuration.component';
import { FeatureToggleComponent } from './feature-toggle/feature-toggle.component';
import { IntegrationTokenConfigurationComponent } from "./integration-tokens-configuration/integration-token-configuration.component";
import { LoginComponent } from './login/components/login/login.component';
import { PasswordRecoveryComponent } from './login/components/password-recovery/password-recovery.component';
import { OperationsProposalsComponent } from './operations-proposals/operations-proposals.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { PriceCampaignComponent } from './price-campaign/price-campaign.component';
import { PriceCheckConfigComponent } from './price-check-config/price-check-config.component';
import { PricePremiumUfComponent } from './price-premium-uf/price-premium-uf.component';
import { PricingProposalsComponent } from './pricing-proposals/pricing-proposals.component';
import { ProfilesComponent } from './profile/profiles.component';
import { ReportsComponent } from './reports/reports.component';
import { SearchProposalsComponent } from './search-proposals/search-proposals.component';
import { ServiceConfigComponent } from './service-config/service-config.component';
import { BackofficeGuard } from './shared/guards/backoffice.guard';
import { LoginGuard } from './shared/guards/login.guard';
import { TopicStatucConfigurationComponent } from './topic-status-configuration/topic-status-configuration.component';
import { UsersComponent } from './users/users.component';
import { ConfigurationThreeDsComponent } from './configuration-three-ds/configuration-three-ds.component';
import { ConfigurationSimulatorComponent } from './configuration-simulator/configuration-simulator.component';
import { SecretResetComponent } from './login/components/password-reset/secret-reset.component';
import { ResellerCloverConfigurationComponent } from './reseller-clover-configuration/reseller-clover-configuration.component';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    children: [
      { path: '', component: LoginComponent },
    ]
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'password-reset',
    component: SecretResetComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'service-config',
        component: ServiceConfigComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'payment-gateway',
        component: PaymentGatewayComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'search-proposals',
        component: SearchProposalsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'price-premium-uf',
        component: PricePremiumUfComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'configuration-setup',
        component: ConfigurationSetupComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'configuration-checklist',
        component: ConfigurationChecklistComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'compliance-proposals',
        component: ComplianceProposalsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'price-campaign',
        component: PriceCampaignComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'price-check-config',
        component: PriceCheckConfigComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'cnae-compliance-register',
        component: CnaeComplianceRegisterComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'faq-category',
        component: FAQCategoryComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'cnae-credit-risk-register',
        component: CnaeCreditRiskRegisterComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'ecommerce-cnae-whitelist',
        component: EcommerceCnaeWhitelistComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'price-economical-group',
        component: EconomicalGroupComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'profiles',
        component: ProfilesComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'bw-service-contract-config',
        component: BwServiceContractConfigComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'operations-proposals',
        component: OperationsProposalsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'credit-risk-proposals',
        component: CreditRiskProposalsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'scheduled-maintenance',
        component: ApiStatusComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'configuration-professional-license',
        component: ConfigurationProfessionalLicenseComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'pricing-proposals',
        component: PricingProposalsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'configuration-app',
        component: ConfigAppComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'conciliators',
        component: ConciliatorComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'integration-token-configuration',
        component: IntegrationTokenConfigurationComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'topic-configuration',
        component: TopicStatucConfigurationComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'faq-configuration',
        component: FaqConfigurationComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'engine-configuration',
        component: FeatureToggleComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'three-ds-configuration',
        component: ConfigurationThreeDsComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'configuration-simulator',
        component: ConfigurationSimulatorComponent,
        canActivate: [BackofficeGuard]
      },
      {
        path: 'reseller-clover-configuration',
        component: ResellerCloverConfigurationComponent,
        canActivate: [BackofficeGuard]
      },
      // {
      //   path: 'price-economical-group-convenience',
      //   component: EconomicalGroupConvenienceComponent,
      //   canActivate: [BackofficeGuard]
      // },
    ]
  },
  { path: '**', redirectTo: '/login' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
