import { Component, Inject } from '@angular/core';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { UserModel } from 'src/app/shared/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { cpfMask } from 'src/app/shared/masks/document-masks';
import { PhoneMask } from 'src/app/shared/masks/phone-mask';
import { EmailMask } from 'src/app/shared/masks/email-mask';
import { Messages } from 'src/app/shared/messages/messages';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { RequiredIfValidator } from 'src/app/shared/validators/required-if-validator';
import { CompareValidator } from 'src/app/shared/validators/compare-validator';
import { CompareTypeEnum } from 'src/app/shared/enums/compare-type.enum';
import { PasswordModel } from 'src/app/shared/models/password.model';
import { PasswordValidator } from 'src/app/shared/validators/password-validator';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/shared/service/error.service';
import { ProfileModel } from '../../../shared/models/profile.model';
import { LoginService } from 'src/app/login/services/login.service';
import { ServiceContractSelectModalComponent, ServiceContractSelectModalDialogData } from './service-contract-select-modal/service-contract-select-modal.component';
import { ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';
import { CpfValidator } from 'src/app/shared/validators/cpf-validator';
import { BaseConciliatorModel, ConciliatorModel } from 'src/app/conciliator/models/conciliator.model';
import { ConciliatorService } from 'src/app/conciliator/services/conciliator.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {

  private readonly CPF_REGEX = /^[0-9]{11}$/;
  private readonly CNPJ_REGEX = /^[0-9]{14}$/;

  existingUser = false;
  formGroup: FormGroup;
  passwordDefinitionMethods: Item[] = [
    {
      value: SecretDefinitionMethodEnum.FORM,
      label: 'Definir a senha agora'
    },
    {
      value: SecretDefinitionMethodEnum.EMAIL,
      label: "Receber um link por e-mail"
    }
  ];
  fields: FdFieldConfigs;
  lastUserTypeSelected: UserTypeEnum;
  currentStep: UserCreateStepEnum = UserCreateStepEnum.DATA_CREATE;
  userTypes: Item[] = [];
  tokenChannelOptions: Item[] = [
    {
      value: 'SMS',
      selected: true,
      label: 'SMS'
    },
    {
      value: 'EMAIL',
      label: 'E-mail'
    }
  ]
  conciliators: Item[] = [];


  get isInitialStep() {
    return this.currentStep === UserCreateStepEnum.DATA_CREATE;
  }

  get isPasswordStep() {
    return this.currentStep === UserCreateStepEnum.PASSWORD_CREATE;
  }


  get isTokenStep() {
    return this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE;
  }

  get isPasswordDefinitionAsEmail() {
    return this.formGroup &&
      this.formGroup.value &&
      this.formGroup.value.passwordDefinitionMethod &&
      this.formGroup.value.passwordDefinitionMethod === SecretDefinitionMethodEnum.EMAIL
  }

  get isPasswordDefinitionAsForm() {
    return this.formGroup &&
      this.formGroup.value &&
      this.formGroup.value.passwordDefinitionMethod &&
      this.formGroup.value.passwordDefinitionMethod === SecretDefinitionMethodEnum.FORM;
  }

  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private authService: AuthService,
    private errorService: ErrorService,
    private userEmailService: LoginService,
    private hierarchyService: HierarchyService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cpf: string,
      name: string
    },
    private formBuilder: FormBuilder,
    private conciliatorService: ConciliatorService,
  ) { }

  get userType(): UserTypeEnum {
    return this.isAdmin ? UserTypeEnum.ADMIN
    : this.isSupervisor ? UserTypeEnum.SUPERVISOR
    : this.isVendor ? UserTypeEnum.VENDOR
    : this.isConciliator ? UserTypeEnum.CONCILIATOR
    : UserTypeEnum.SIMULATOR;
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.SUPERVISOR,
        label: 'Supervisor'
      });
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.VENDOR,
        label: 'Vendedor'
      });

    }
    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.ADMIN,
        label: 'Administrador'
      });
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SIMULATOR_HIERARCHY])) {
      this.userTypes.push({
        value: UserTypeEnum.SIMULATOR,
        label: 'Simulador'
      });
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_BACKOFFICE_USER_MASTER])) {
      this.userTypes.push({
        value: UserTypeEnum.CONCILIATOR,
        label: 'Conciliadora'
      })
    }

    this.setUserType(this.userType);

    this.formGroup.controls.channel.setValidators([
      RequiredIfValidator(() => this.isVendor || !this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]))
    ])

    this.formGroup.controls.channelType.setValidators([
      RequiredIfValidator(() => this.isVendor || !this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]))
    ])


    this.formGroup.controls.passwordConfirmation.setValidators(
      [
        RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.PASSWORD_CREATE),
        CompareValidator(this.formGroup.controls.password, CompareTypeEnum.EQUAL)
      ]
    );


    this.formGroup.controls.passwordDefinitionMethod.setValidators(
      [
        RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE && !this.existingUser)
      ]
    );

    this.formGroup.controls.tokenChannel.setValidators(
      [
        RequiredIfValidator(() => this.isPasswordDefinitionAsForm)
      ]
    );

    this.formGroup.controls.institution.setValidators(
      [
        RequiredIfValidator(() => this.isVendor)
      ]
    );

    this.formGroup.controls.userConciliator.setValidators(
      [
        RequiredIfValidator(() => this.isConciliator)
      ]
    );
  }

  get isFullAccessMarked() {
    return this.formGroup &&
      this.formGroup.value &&
      !!this.formGroup.value.fullAccess;
  }

  get isSimulator() {
    if (this.hasMoreThanOnePermission) {
      return this.formGroup.value.userType === UserTypeEnum.SIMULATOR;
    }
    else {
      return this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]);
    }
  }

  get isVendor() {
    if (this.hasMoreThanOnePermission) {
      return this.formGroup.value.userType === UserTypeEnum.VENDOR;
    }
    else {
      return this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]);
    }
  }

  get isSupervisor() {
    if (this.hasMoreThanOnePermission) {
      return this.formGroup.value.userType === UserTypeEnum.SUPERVISOR;
    }
    else {
      return !this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]) &&
        this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]);
    }
  }

  get isAdmin() {
    if (this.hasMoreThanOnePermission) {
      return this.formGroup.value.userType === UserTypeEnum.ADMIN;
    }
    else {
      return !this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]) &&
        this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]);
    }
  }

  get isConciliator() {
    if (this.hasMoreThanOnePermission) {
      return this.formGroup.value.userType === UserTypeEnum.CONCILIATOR;
    }
    else {
      return !this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]) &&
        !this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]) &&
        this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE]);
    }
  }

  get hasMoreThanOnePermission() {
    const hasPermissionMap = [
      this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE]),
      this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE]),
      this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE])
    ];

    return hasPermissionMap.filter(x => !!x).length > 1;
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      cpfCnpj: ['', [Validators.required, CpfValidator]],
      mobileNumber: ['', Validators.required],
      fullAccess: [''],
      email: ['', Validators.required],
      userType: ['', RequiredIfValidator(() => this.hasMoreThanOnePermission)],
      userProfile: ['', Validators.required],
      tokenChannel: ['', Validators.required],
      institution: ['', Validators.required],
      serviceContracts: ['', RequiredIfValidator(() => !this.isFullAccessMarked)],
      channelType: [''],
      channel: [''],
      subChannel: [''],
      agent: [''],
      token: ['', RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE)],
      passwordDefinitionMethod: [''],
      password: ['', [PasswordValidator, RequiredIfValidator(() => this.currentStep === UserCreateStepEnum.PASSWORD_CREATE)]],
      passwordConfirmation: [''],
      userConciliator: ['', Validators.required]
    });
  }

  returnOnlyDigits(value: string) {
    if (value) {
      return value.replace(/[^0-9]/g, '');
    }
  }

  resendToken() {
    this.loadingService.show();
    const data: UserModel = {
      agent: this.formGroup.value.agent || null,
      channel: this.formGroup.value.channel || null,
      channelType: this.formGroup.value.channelType || null,
      subChannel: this.formGroup.value.subChannel || null,
      cpfCnpj: this.returnOnlyDigits(this.formGroup.value.cpfCnpj),
      email: this.formGroup.value.email,
      isEnabled: false,
      type: this.userType,
      institution: this.formGroup.value.institution,
      name: this.formGroup.value.name,
      mobileNumber: this.returnOnlyDigits(this.formGroup.value.mobileNumber),
      serviceContracts: this.getServiceContracts(),
      profileId: this.formGroup.value.userProfile
    }

    if (!this.isTokenStep && !this.requiredFieldsValid(data)) {
      return;
    }

    this.userService.resendToken(data, this.formGroup.value.tokenChannel)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.TOKEN_SUCCESSFULLY_RESENT);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.TOKEN_RESEND_ERROR));
  }



  requiredFieldsValid(data: UserModel) {
    if (this.isVendor) {
      return this.validateVendorRequiredFields(data);
    } else if (this.isSimulator) {
      return this.validateSimulatorRequiredFields(data);
    } else if (this.isAdmin) {
      return this.validateAdminRequiredFields(data);
    } else if (this.isSupervisor) {
      return this.validateSupervisorRequiredFields(data);
    } else if (this.isConciliator) {
      return this.validateConciliatorRequiredFields(data);
    }
    else {
      return false;
    }
  }

  validateAdminRequiredFields(data: UserModel) {
    if (!data.institution ||
      !data.serviceContracts ||
      !data.cpfCnpj ||
      !data.email ||
      !data.name ||
      !data.type ||
      !this.formGroup.value.tokenChannel ||
      !data.mobileNumber
    ) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      this.loadingService.hide();
      return false;
    } else {
      return true;
    }
  }

  validateConciliatorRequiredFields(data: UserModel) {
    if (!data.institution ||
      !data.cpfCnpj ||
      !data.email ||
      !data.name ||
      !data.type ||
      !this.formGroup.value.tokenChannel ||
      !data.mobileNumber
    ) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      this.loadingService.hide();
      return false;
    } else {
      return true;
    }
  }

  setUserType(userType: UserTypeEnum) {
    if (!userType) { return; }

    this.clearFieldValues([
      userType !== UserTypeEnum.ADMIN ? HierarchyFieldsEnum.SERVICE_CONTRACT : null,
      HierarchyFieldsEnum.AGENT_CHANNEL,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.CHANNEL,
    ])

    this.loadInstitutions();
    if (userType === UserTypeEnum.ADMIN) {
      (this.fields.channel as FdSelectConfig).items = [];
      (this.fields.subChannel as FdSelectConfig).items = [];
      (this.fields.agent as FdSelectConfig).items = [];
      (this.fields.channelType as FdSelectConfig).items = [];
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agent.disable();
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agent.setValue('');
      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.userProfile.enable();
      this.formGroup.controls.userConciliator.disable();
      (this.fields.userConciliator as FdSelectConfig).items = [];
    } else if (userType === UserTypeEnum.VENDOR) {
      if (this.lastUserTypeSelected === UserTypeEnum.ADMIN) {
        this.formGroup.controls.channel.enable();
        this.formGroup.controls.subChannel.enable();
        this.formGroup.controls.agent.enable();
        this.formGroup.controls.channelType.enable();
      }
      this.formGroup.controls.userProfile.setValue(null);
      this.formGroup.controls.userProfile.disable();
      this.formGroup.controls.userConciliator.disable();
      (this.fields.userConciliator as FdSelectConfig).items = [];
      this.loadChannelTypes();
    } else if (userType === UserTypeEnum.SIMULATOR) {
      if (this.lastUserTypeSelected === UserTypeEnum.ADMIN) {
        this.formGroup.controls.channel.enable();
        this.formGroup.controls.subChannel.enable();
        this.formGroup.controls.agent.disable();
        this.formGroup.controls.channelType.enable();
      }
      this.formGroup.controls.agent.setValue('');
      this.formGroup.controls.userProfile.setValue(null);
      this.formGroup.controls.userProfile.disable();
      this.formGroup.controls.userConciliator.disable();
      (this.fields.userConciliator as FdSelectConfig).items = [];
      this.loadChannelTypes();
    }
    else if (userType === UserTypeEnum.SUPERVISOR) {
      if (this.lastUserTypeSelected === UserTypeEnum.ADMIN) {
        this.formGroup.controls.channel.enable();
        this.formGroup.controls.subChannel.enable();
        this.formGroup.controls.channelType.enable();
      }
      this.formGroup.controls.userProfile.setValue(null);
      this.formGroup.controls.userProfile.disable();
      this.formGroup.controls.userConciliator.disable();
      (this.fields.userConciliator as FdSelectConfig).items = [];
      this.loadChannelTypes();
    } else if (userType === UserTypeEnum.CONCILIATOR) {
      (this.fields.channel as FdSelectConfig).items = [];
      (this.fields.subChannel as FdSelectConfig).items = [];
      (this.fields.agent as FdSelectConfig).items = [];
      (this.fields.channelType as FdSelectConfig).items = [];
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agent.disable();
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agent.setValue('');
      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.userProfile.enable();
      this.formGroup.controls.userConciliator.enable();
      this.loadConciliators();
      this.formGroup.value.serviceContracts = [];
    }
    this.lastUserTypeSelected = userType;
  }

  validateSupervisorRequiredFields(data: UserModel) {
    if (!data.institution ||
      !data.serviceContracts ||
      !data.channel ||
      !data.channelType ||
      !data.cpfCnpj ||
      !data.email ||
      !data.name ||
      !data.type ||
      !this.formGroup.value.tokenChannel ||
      !data.mobileNumber
    ) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      this.loadingService.hide();
      return false;
    }
    else {
      return true;
    }
  }

  validateVendorRequiredFields(data: UserModel) {
    if (!data.institution ||
      !data.serviceContracts ||
      !data.channel ||
      !data.channelType ||
      !data.cpfCnpj ||
      !data.email ||
      !data.name ||
      !data.type ||
      !this.formGroup.value.tokenChannel ||
      !data.mobileNumber
    ) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      this.loadingService.hide();
      return false;
    }
    else {
      return true;
    }
  }

  validateSimulatorRequiredFields(data: UserModel) {
    if (!data.institution ||
      !data.serviceContracts ||
      !data.channel ||
      !data.channelType ||
      !data.cpfCnpj ||
      !data.email ||
      !data.name ||
      !data.type ||
      !this.formGroup.value.tokenChannel ||
      !data.mobileNumber
    ) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      this.loadingService.hide();
      return false;
    }
    else {
      return true;
    }
  }

  serializeServiceContracts(serviceContractList: Item[]) {
    if (!serviceContractList || !serviceContractList.length) { return; }
    return serviceContractList.map(x => x.value);
  }

  getServiceContracts() {
    if (this.isAdmin) {
      if (this.isFullAccessMarked) {
        return [];
      }
      else {
        return this.serializeServiceContracts(this.formGroup.value.serviceContracts);
      }
    }
    else {
      if (!this.formGroup.value.serviceContracts) {
        return [];
      }
      return [this.formGroup.value.serviceContracts];
    }
  }

  createUser() {
    if (this.isFullAccessMarked) {
      this.formGroup.controls.serviceContracts.disable();
    }
    else if (!this.isConciliator) {
      this.formGroup.controls.serviceContracts.enable();
    }
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    const data: UserModel = {
      agent: this.formGroup.value.agent || null,
      channel: this.formGroup.value.channel || null,
      channelType: this.formGroup.value.channelType || null,
      subChannel: this.formGroup.value.subChannel || null,
      cpfCnpj: this.returnOnlyDigits(this.formGroup.value.cpfCnpj),
      email: this.formGroup.value.email,
      isEnabled: false,
      type: this.userType,
      institution: this.formGroup.value.institution,
      name: this.formGroup.value.name,
      mobileNumber: this.returnOnlyDigits(this.formGroup.value.mobileNumber),
      serviceContracts: this.getServiceContracts(),
      profileId: this.formGroup.value.userProfile,
      conciliatorId: this.formGroup.value.userConciliator
    };

    if (this.isVendor && !this.formGroup.value.subChannel) {
      this.dialogService.openDialog(Messages.ALL_SUBCHANNELS_WITH_SAME_CPF_ALERT, () => this.continueCreateUser(data));
      return;
    }

    this.continueCreateUser(data);
  }

  continueCreateUser(data: UserModel) {

    if (this.existingUser) {
      this.loadingService.show();
      this.userService.createUserByForm(data, this.formGroup.value.tokenChannel)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe((response) => {
          if (response && response.system) {
            this.dialogService.openDialogWithMessage(`Alteração realizada com sucesso. Utilize a senha definida no sistema ${response.system}`, () => this.close());
          }
          return;
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }

    const passwordDefinitionMethod = this.formGroup.value.passwordDefinitionMethod;

    if (!passwordDefinitionMethod) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }


    if (this.isPasswordDefinitionAsEmail) {
      this.loadingService.show();
      this.userService.createUserByEmail(data)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe((response) => {

          if (!response) {
            this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR, () => this.close());
            return;
          }

          this.dialogService.openDialog(Messages.CREATE_USER_LINK_SENT_TO_EMAIL, () => this.close());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }

    else if (this.isPasswordDefinitionAsForm) {
      this.loadingService.show();
      this.userService.createUserByForm(data, this.formGroup.value.tokenChannel)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(() => {
          this.currentStep = UserCreateStepEnum.TOKEN_VALIDATE;
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }
  }

  validateUserReceivedToken() {

    this.loadingService.show();

    let token = this.formGroup.value.token;

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.userService.validateUserReceivedTokenByFormCreation(token, this.returnOnlyDigits(this.formGroup.value.cpfCnpj))
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.currentStep = UserCreateStepEnum.PASSWORD_CREATE;
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  createPassword() {

    const data: PasswordModel = {
      confirmPassword: this.formGroup.value.passwordConfirmation,
      cpfCnpj: this.returnOnlyDigits(this.formGroup.value.cpfCnpj),
      password: this.formGroup.value.password,
      token: this.formGroup.value.token
    }

    if (!this.existingUser && !data.token) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if (!data.confirmPassword ||
      !data.cpfCnpj ||
      !data.password ||
      !this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.userService.createPassword(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
  }

  cpfCnpjChange(document: string) {
    if ((this.CPF_REGEX.test(document) || this.CNPJ_REGEX.test(document)) && this.formGroup.controls.cpfCnpj.valid) {
      this.loadingService.show();
      this.userService.getExistingUserInformation(document)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(userInformation => {
          if (userInformation.isAlreadyUser) {
            this.existingUser = true;
            this.formGroup.controls.name.setValue(userInformation.details.name)
            this.formGroup.controls.mobileNumber.setValue(userInformation.details.mobileNumber)
            this.formGroup.controls.email.setValue(userInformation.details.email)
            this.dialogService.openDialog(Messages.USER_ALREADY_EXISTS);
            return;
          }
        }, (err: HttpErrorResponse) => {
          this.errorService.handleXHRError(err, Messages.GENERAL_ERROR);
          console.error('[Access Management Client] - Unspecified error when querying existing user');
          this.loadingService.hide();
        });
    }
  }


  save(): void {
    this.loadingService.show();

    if (this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE) {
      this.validateUserReceivedToken();
    }
    else if (this.currentStep === UserCreateStepEnum.PASSWORD_CREATE) {
      this.createPassword();
    }
    else {
      this.createUser();
    }
  }

  close() {
    this.dialogRef.close();
  }

  loadInstitutions(): void {

    this.loadingService.show();

    this.clearFieldValues([
      HierarchyFieldsEnum.SERVICE_CONTRACT,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    (this.fields.serviceContracts as FdSelectConfig).items = [];
    (this.fields.channelType as FdSelectConfig).items = [];
    (this.fields.channel as FdSelectConfig).items = [];
    (this.fields.subChannel as FdSelectConfig).items = [];
    (this.fields.agent as FdSelectConfig).items = [];

    this.formGroup.controls.serviceContracts.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agent.disable();


    this.formGroup.controls.serviceContracts.setValue('');
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agent.setValue('');

    this.hierarchyService.institutionByUserType(this.userType)

    this.hierarchyService.institutionByUserType(this.userType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institution as FdSelectConfig).items = [];
          (this.fields.institution as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
      });
  }

  setSelectedServiceContracts(response: InstitutionAndServiceContractModel[], selectedItems: Item[]) {

    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const selectedItemsCodes = selectedItems.map(x => x.value);
    response.forEach(institution => {
      institution.serviceContracts.map(item => item.selected = selectedItemsCodes.includes(item.value));
    })
  }

  openDialog() {

    this.hierarchyService.getAllServiceContracts()
      .subscribe(response => {

        this.setSelectedServiceContracts(response, this.formGroup.value.serviceContracts);

        const dialogData: ServiceContractSelectModalDialogData = {
          itemGroups: response,
          title: 'service contracts',
          formControl: this.formGroup.controls.serviceContracts,
          formGroup: this.formGroup
        };

        const dialogRef = this.dialog.open(ServiceContractSelectModalComponent, {
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          height: '500px',
          data: dialogData
        });
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SERVICE_CONTRACT_LOAD_ERROR));
  }

  loadServiceContracts(): void {

    this.clearFieldValues([
      HierarchyFieldsEnum.SERVICE_CONTRACT,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let institutionNumber = this.formGroup.value.institution;

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agent.setValue('');

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    this.loadingService.show();

    this.hierarchyService.serviceContractByInstitutionAndUserType(institutionNumber, this.userType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContracts as FdSelectConfig).items = [];
          (this.fields.serviceContracts as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContracts.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContracts.disable();
        this.formGroup.controls.serviceContracts.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  loadChannelTypes(): void {

    let institutionNumber = this.formGroup.value.institution;

    if (this.isAdmin || !institutionNumber) {
      return;
    }

    const arr = this.isSupervisor && this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]) ? [{ value: '', label: 'Todos' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    this.loadingService.show();
    this.hierarchyService.channelType(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.channelType as FdSelectConfig).items = arr;
          (this.fields.channelType as FdSelectConfig).items.push(...data);
          this.formGroup.controls.channelType.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.channelType.disable();
        this.formGroup.controls.channelType.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.CHANNEL_TYPE_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.CHANNEL_TYPE_LOAD_ERROR);
      });
  }

  loadChannels(): void {

    const arr = this.isSupervisor && this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]) ? [{ value: '', label: 'Todos' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let channelType = this.formGroup.value.channelType || null;

    if(!channelType){
      return;
    }

    this.loadingService.show();
    this.hierarchyService.channel(channelType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.channel as FdSelectConfig).items = arr;
          (this.fields.channel as FdSelectConfig).items.push(...data);
          this.formGroup.controls.channel.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.channel.disable();
        this.formGroup.controls.channel.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.CHANNEL_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.CHANNEL_LOAD_ERROR);
      });
  }

  loadSubChannels(): void {

    const subChannelArr = this.isSupervisor || this.isVendor || this.isSimulator ? [{ value: '', label: 'Todos' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let channel = this.formGroup.value.channel;
    let channelType = this.formGroup.value.channelType;

    if (!channel || !channelType) {
      return;
    }

    this.loadingService.show();
    this.hierarchyService.subChannel(channel, channelType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.subChannel as FdSelectConfig).items = subChannelArr;
          (this.fields.subChannel as FdSelectConfig).items.push(...data);
          this.formGroup.controls.subChannel.enable();
        }
      }, (error: HttpErrorResponse) => {

        this.clearFieldValues([
          HierarchyFieldsEnum.SUB_CHANNEL,
        ]);

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SUB_CHANNEL_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.SUB_CHANNEL_LOAD_ERROR);
      });
  }

  loadAgentChannels(): void {

    if (this.isSupervisor) {
      return;
    }

    const agentArr = this.isVendor ? [{ value: '', label: 'Nenhum' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let subChannel = this.formGroup.value.subChannel;
    let channel = this.formGroup.value.channel;
    let channelType = this.formGroup.value.channelType;


    if (!subChannel || !channel || !channelType) {
      return;
    }

    this.loadingService.show();
    this.hierarchyService.agentChannel(subChannel, channel, channelType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.agent as FdSelectConfig).items = agentArr;
          (this.fields.agent as FdSelectConfig).items.push(...data);
          this.formGroup.controls.agent.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.clearFieldValues([HierarchyFieldsEnum.AGENT_CHANNEL])
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.AGENT_CHANNEL_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.AGENT_CHANNEL_LOAD_ERROR);
      });
  }

  loadProfiles() {
    (this.fields.userProfile as FdSelectConfig).items = [];
    this.userService.getAllProfiles().pipe(map(p => this.mapToProfileModel(p))).subscribe(data => {
      (this.fields.userProfile as FdSelectConfig).items.push(...data);
    });
  }

  loadConciliators() {
    (this.fields.userConciliator as FdSelectConfig).items = [];
    this.conciliatorService.getAllActiveConciliators().pipe(map(p => this.mapToConciliatorModel(p))).subscribe(data => {
      (this.fields.userConciliator as FdSelectConfig).items.push(...data);
    });
  }

  private mapToProfileModel(response: ProfileModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
      .map(value => ({
        value: value.id,
        label: value.description,
      }));
  }


  clearFieldValues(fields: HierarchyFieldsEnum[]) {
    fields.forEach(item => {
      switch (item) {
        case HierarchyFieldsEnum.INSTITUTION:
          (this.fields.institution as FdSelectConfig).items = [];
          this.formGroup.controls.institution.disable();
          this.formGroup.controls.institution.setValue('');
          break;

        case HierarchyFieldsEnum.SERVICE_CONTRACT:
          (this.fields.serviceContracts as FdSelectConfig).items = [];
          this.formGroup.controls.serviceContracts.disable();
          this.formGroup.controls.serviceContracts.setValue('');
          break;

        case HierarchyFieldsEnum.CHANNEL_TYPE:
          (this.fields.channelType as FdSelectConfig).items = [];
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          break;

        case HierarchyFieldsEnum.CHANNEL:
          (this.fields.channel as FdSelectConfig).items = [];
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          break;

        case HierarchyFieldsEnum.AGENT_CHANNEL:
          (this.fields.agent as FdSelectConfig).items = [];
          this.formGroup.controls.agent.disable();
          this.formGroup.controls.agent.setValue('');
          break;

        case HierarchyFieldsEnum.SUB_CHANNEL:
          (this.fields.subChannel as FdSelectConfig).items = [];
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          break;
      }
    })
  }

  private mapToConciliatorModel(response: BaseConciliatorModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
      .map(value => ({
        value: value.id,
        label: value.name,
      }));
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome',
        controlName: 'name',
        messages: {
          required: 'Informe um nome'
        }
      },
      cpfCnpj: {
        label: 'CPF',
        mask: cpfMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF',
          invalidCpf: 'CPF inválido'
        }
      },
      mobileNumber: {
        label: 'Telefone',
        mask: PhoneMask,
        controlName: 'mobileNumber',
        messages: {
          required: 'Informe um telefone',
          invalid: 'telefone inválido'
        }
      },
      email: {
        label: 'E-mail',
        mask: EmailMask,
        controlName: 'email',
        messages: {
          required: 'Informe um e-mail',
          invalid: 'e-mail inválido'
        }
      },
      userProfile: {
        label: 'Perfil',
        items: [],
        controlName: 'userProfile',
        messages: {
          required: 'Informe um perfil'
        }
      },
      userType: {
        label: 'Tipo do usuario',
        items: this.userTypes,
        controlName: 'userType',
        messages: {
          required: 'Informe o tipo'
        }
      },
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Instituição inválida'
        }
      },
      serviceContracts: {
        label: 'Service Contract',
        disabled: true,
        items: [],
        showValueOnPlaceholder: true,
        controlName: 'serviceContracts',
        messages: {
          required: 'Informe um service contract'
        }
      },
      channel: {
        label: 'Canal',
        disabled: true,
        items: [],
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        disabled: true,
        items: [],
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        disabled: true,
        items: [],
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
      agent: {
        label: 'Agente',
        disabled: true,
        items: [],
        controlName: 'agent',
        messages: {
          required: 'Informe um Agente'
        }
      },
      token: {
        label: 'Token',
        controlName: 'token',
        messages: {
          required: 'Informe o token'
        }
      },
      fullAccess: {
        label: 'Todos os services contracts',
        controlName: 'fullAccess',
        messages: {
        }
      },
      tokenChannel: {
        controlName: 'tokenChannel',
        items: this.tokenChannelOptions,
        messages: {
          required: 'Informe a forma de envio do token.'
        }
      },
      passwordDefinitionMethod: {
        label: 'Método para definição da senha',
        controlName: 'passwordDefinitionMethod',
        items: this.passwordDefinitionMethods,
        messages: {
          required: 'Informe um método para definição da senha'
        }
      },
      password: {
        label: 'Senha',
        hint: 'A senha deve conter pelo menos 1 letra minúscula, 1 letra maiúscula, 1 número e 1 caracter especial, que pode ser um dos seguintes: @#$%^&+=',
        type: InputType.PASSWORD,
        controlName: 'password',
        messages: {
          required: 'Informe a senha',
          specialCharsMatch: 'A senha deve conter pelo menos 1 caracter especial (@#$%^&+=)',
          numberMatch: 'A senha deve conter pelo menos 1 número',
          lowerCaseMatch: 'A senha deve conter pelo menos 1 letra minúscula',
          upperCaseMatch: 'A senha deve conter pelo menos 1 letra maiúscula'
        }
      },
      passwordConfirmation: {
        label: 'Confirme a senha',
        type: InputType.PASSWORD,
        controlName: 'passwordConfirmation',
        messages: {
          required: 'Informe a senha',
          equal: 'As senhas não conferem'
        }
      },
      userConciliator: {
        label: 'Conciliadora',
        items: this.conciliators,
        controlName: 'userConciliator',
        messages: {
          required: 'Informe a conciliadora'
        }
      },
    };
  }

}

export enum UserCreateStepEnum {
  DATA_CREATE = 1,
  TOKEN_VALIDATE = 2,
  PASSWORD_CREATE = 3
}

export enum HierarchyFieldsEnum {
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  INSTITUTION = 'INSTITUTION',
  CHANNEL_TYPE = 'CHANNEL_TYPE',
  CHANNEL = 'CHANNEL',
  AGENT_CHANNEL = 'AGENT_CHANNEL',
  SUB_CHANNEL = 'SUB_CHANNEL'
}


export enum SecretDefinitionMethodEnum {
  FORM = 'FORM',
  EMAIL = 'EMAIL'
}

export interface InstitutionAndServiceContractModel {
  id: string;
  name: string;
  serviceContracts: Item[];
}
