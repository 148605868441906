import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../../../shared/fd-form-components/fd-form-components.module';
import { InputType } from '../../../shared/fd-form-components/fd-input/fd-input.component';
import { cpfMask } from '../../../shared/masks/document-masks';
import { Messages } from '../../../shared/messages/messages';
import { LoginService } from '../../services/login.service';
import { LoginModel } from '../../../shared/models/login.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth.service';
import { LoadingService } from '../../../shared/service/loading.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '../../../shared/service/dialog.service';
import { ErrorService } from '../../../shared/service/error.service';
import { Routes } from 'src/app/shared/routing/routes';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { stringToBoolean } from 'src/app/shared/utils/utils-app';
import {Observable} from "rxjs";
import {AuthServiceModel} from "../../../shared/models/response/auth-service.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;

  loginActive = true;
  recaptchaActive = false;
  recaptchaIsValid = false;
  isMFA: boolean = false;
  correlationId:string;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private router: Router,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit() {
    this.getRecaptchaConfig();
    this.loadingService.hide();
    this.startForms();
  }

  startForms(): void {
    this.formGroup = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      tokenRecaptcha:[],
      mfaToken:[]
    });

    this.fields = {
      user: {
        label: 'CPF',
        disabled: false,
        controlName: 'user',
        mask: cpfMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido'
        }
      },
      password: {
        label: 'Senha',
        disabled: false,
        controlName: 'password',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha',
          invalid: 'Senha inválida'
        }
      },
      tokenRecaptcha: {
        label: 'tokenRecaptcha',
        disabled: true,
        controlName: 'tokenRecaptcha',
        type: InputType.TEXT,
        messages: {
          required: 'Marque a caixa de seleção reCAPTCHA',
          invalid: 'Token reCaptcha inválido'
        }
      },
      mfaToken: {
        label: 'Informe o token recebido por email',
        disabled: false,
        controlName: 'mfaToken',
        type: InputType.TEXT,
        messages: {
          required: 'Informe o token recebido por email',
          invalid: 'Token invalido'
        }
      }
    };
  }

  goToPasswordRecovery() {
    this.router.navigate(['/password-recovery']);
  }

  defineNextPage() {

    const roles = this.authService.getUserRoles();

    switch (true) {
      case this.authService.isUserInRoles([AdminRolesEnum.MONITORING]):
        this.router.navigate([Routes.SEARCH_PROPOSALS]);
        return;

      case this.authService.isUserInRoles([AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE]):
        this.router.navigate([Routes.PRICE_CAMPAIGN])
        return;

      case this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE]):
        this.router.navigate([Routes.SERVICE_CONFIG]);
        return;

      case this.authService.isUserInRoles([AdminRolesEnum.USERS_READ, AdminRolesEnum.USERS_ADMIN_WRITE, AdminRolesEnum.USERS_VENDOR_WRITE]):
        this.router.navigate([Routes.USERS]);
        return;

      case this.authService.isUserInRoles([AdminRolesEnum.CNAE_CREDIT_RISK_READ, AdminRolesEnum.CNAE_CREDIT_RISK_WRITE]):
        this.router.navigate([Routes.CNAE_CREDIT_RISK_REGISTER]);
        return;
    }
  }

  reenviarToken() {
    this.loadingService.show();
    this.loginActive = false;

    const data = new LoginModel();
    data.cpfCnpj = this.formGroup.value.user;
    data.senha = this.formGroup.value.password;
    data.tokenRecaptcha = this.formGroup.value.tokenRecaptcha;
    let request: Observable<AuthServiceModel>

    request = this.loginService.login(data);
    if(this.isLoginAllowed()) {
      request
          .pipe(finalize(() => this.loadingService.hide()))
          .subscribe(response => {
            if(response.otpDetails && response.otpDetails.correlationId) {
              this.isMFA = true;
              this.correlationId = response.otpDetails.correlationId;
              this.loginActive = true;
            }
          }, (err: HttpErrorResponse) => {
            this.loginActive = true;
            this.recaptchaReset();
            this.errorService.handleXHRError(err, Messages.LOGIN_ERROR);
          });
    } else {
      this.loginActive = true;
      this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_RECAPTCHA);
    }
  }

  login() {
    this.loadingService.show();
    this.loginActive = false;

    const data = new LoginModel();
    data.cpfCnpj = this.formGroup.value.user;
    data.senha = this.formGroup.value.password;
    data.tokenRecaptcha = this.formGroup.value.tokenRecaptcha;
    let request: Observable<AuthServiceModel>
    if(this.isMFA) {
      request = this.loginService.loginMFA(data);
      data.correlationId = this.correlationId;
      data.tokenOTP = this.formGroup.value.mfaToken
    } else {
      request = this.loginService.login(data);
    }

    if(this.isLoginAllowed()) {
      request
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        if(response.otpDetails && response.otpDetails.correlationId) {
          this.isMFA = true;
          this.correlationId = response.otpDetails.correlationId;
          this.loginActive = true;
        } else {
          this.authService.loginSuccess(response);
          this.defineNextPage();
          this.loginActive = true;
        }

      }, (err: HttpErrorResponse) => {
        this.loginActive = true;
        this.recaptchaReset();
        this.errorService.handleXHRError(err, Messages.LOGIN_ERROR);
      });
    } else {
      this.loginActive = true;
      this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_RECAPTCHA);
    }

  }

  getRecaptchaConfig() {
    this.loginService.getRecaptchaConfig()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.recaptchaActive = stringToBoolean(response.configValue);
        if(!this.recaptchaActive)
          this.recaptchaIsValid = true;
      }, (err: HttpErrorResponse) => {
        this.errorService.handleXHRError(err, Messages.LOGIN_ERROR_INVALID_RECAPTCHA_CONFIG);
        this.recaptchaActive = false;
      });
  }

  recaptchaReset() {
    if (window.grecaptcha) grecaptcha.reset();
    this.recaptchaIsValid = !this.recaptchaActive;

  }

  public isLoginDisabled(): boolean {
    return !this.loginActive || !this.recaptchaIsValid;
  }

  public isLoginAllowed(): boolean {
    return !this.loginActive && this.recaptchaIsValid;
  }

  public resolvedRecaptcha(token: string): void {
    if(token == null || token == undefined) {
      this.recaptchaIsValid = false;
      this.formGroup.value.tokenRecaptcha = null;
      this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_RECAPTCHA_EXPIRED);
      return
    }

    this.recaptchaIsValid = true;
    this.formGroup.value.tokenRecaptcha = token;
    console.log(`Resolved captcha is valid: ${this.recaptchaIsValid}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.recaptchaIsValid = false;
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_RECAPTCHA);
  }

}
