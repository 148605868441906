<div *ngIf="hasValue" class="container">
    <mat-table class="table-list" [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="id" class="table-list__column-large">
            <mat-header-cell *matHeaderCellDef > Id</mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="instituicao" class="table-list__column">
            <mat-header-cell *matHeaderCellDef > Instituição </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.institution}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="serviceContract" class="table-list__column">
            <mat-header-cell *matHeaderCellDef > Service Contract </mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.serviceContract}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipoCanal" class="table-list__column">
            <mat-header-cell *matHeaderCellDef > Tipo de Canal </mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.channelType || 'Todos'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="canal" class="table-list__column">
            <mat-header-cell *matHeaderCellDef > Canal </mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.channel || 'Todos'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="resellerClover" class="table-list__column">
            <mat-header-cell *matHeaderCellDef > Reseller Clover </mat-header-cell>
            <mat-cell *matCellDef="let element" > {{element.resellerCode}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options_edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="options">
                <ng-container *ngIf="hasWriteAccess">
                    <fa-icon class="edit-link" matTooltip="Editar" icon="edit" (click)="edit(element)">
                    </fa-icon>
                </ng-container>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
    </mat-table>
    <mat-paginator
        [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
        (page)="change($event)" [length]="totalItens">
    </mat-paginator>
</div>
<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
