import { UserTypeEnum } from '../enums/user-type.enum';
import { Item } from '../fd-form-components/fd-select/fd-select.component';

export class UserModel {
    name: string;
    cpfCnpj: string;
    mobileNumber: string;
    email: string;
    institution: string;
    serviceContracts: Item[] | number[];
    userInformation?: UserInformationModel[];
    channelType: string;
    channel: string;
    subChannel: string;
    agent: string;
    type: UserTypeEnum
    isEnabled?: boolean;
    profileId: number;
    profile?: {
        id: number,
        description: string;
    };
    fullAccess?: boolean;
    passwordSet?: boolean;
    conciliatorId?: number;
}

export class UserDetailsModel {
    name: string;
    cpfCnpj: string;
    mobileNumber: string;
    email: string;
    institution: string | string[];
    serviceContracts: Item[] | number[];
    userInformation?: UserInformationModel[];
    channelType: string;
    channel: string;
    subChannel: string;
    agent: string;
    type: UserTypeEnum
    isEnabled: boolean;
    roles: string[];
    profile: {
        id: number,
        description: string;
    };
    fullAccess?: boolean;
}

export class UserEditModel {
    name: string;
    mobileNumber: string;
    cpf: string;
    email: string;
    institution: string;
    serviceContracts: Item[] | number[];
    userInformation?: UserInformationModel[];
    channelType: string;
    channel: string;
    subChannel: string;
    agent: string;
    userType: UserTypeEnum;
    idProfile: number;
    conciliatorId?: number;
}

export class UserListModel {
    name: string;
    cpfCnpj: string;
    mobileNumber: string;
    email: string;
    institution: string;
    userInformation: UserInformationModel[];
    channelType: string;
    channel: string;
    subChannel: string;
    agent: string;
    type: UserTypeEnum
    isEnabled: boolean;
    roles: string[];
    profile: {
        id: number,
        description: string;
    };
    fullAccess: boolean;
    passwordSet?: boolean;
    conciliatorId?: number;
}


export class UserInformationModel {
    serviceContract: number;
    channelType: string;
    channel: string;
    institution: string;
    hierarchyComplementaryInformation: HierarchyComplementaryInformationModel[];
}

export class HierarchyComplementaryInformationModel {
    subChannel: string;
    agentId: string;
}

export class PageableUserModel {
  totalPages: number;
  totalItens: number;
  size: number;
  users: UserModel[];
}

export class UserSearchModel {
    cpf: string;
    institution: string;
    serviceContract: string;
    type: UserTypeEnum
}
