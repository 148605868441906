import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, concat, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';
import { CnaeService } from '../cnae-compliance-register/services/cnae.service';
import { PriceCampaignService } from '../price-campaign/services/price-campaign.service';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { Messages } from '../shared/messages/messages';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { LoadingService } from '../shared/service/loading.service';
import { ConfigurationSimulatorHistoryComponent } from './components/configuration-simulator-history/configuration-simulator-history.component';
import { ParamsSimulatorEnum } from './enums/params-simulator.enum';
import { ConfigurationSimulatorIpbModel } from './models/configuration-simulator-ipb.model';
import { ConfigurationSimulatorModel } from './models/configuration-simulator.model';
import { ConfigurationSimulatorService } from './services/configuration-simulator.service';


@Component({
  selector: 'app-configuration-simulator',
  templateUrl: './configuration-simulator.component.html',
  styleUrls: ['./configuration-simulator.component.scss']
})

export class ConfigurationSimulatorComponent implements OnInit {
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  ipbFormGroup: FormGroup;
  ipbFields: FdFieldConfigs;
  icons: { [key: string]: IconDefinition }
  institutionCaixa: string = '00000007';
  serviceContractCaixa: number = 149;
  campaigns: Item[] = [];
  selectedCampaigns: Item[] = [];
  selectedPreSimulationCampaigns: Item[] = [];
  selectedSimulationCampaigns: Item[] = [];
  cnaes: Item[] = [];
  selectedCnaes: Item[] = [];
  booleanOptions: Item[] = [
    {
      label: 'Sim',
      value: true
    },
    {
      label: "Não",
      value: false
    }
  ];

  originRateOptions: Item[] = [
    {
      label: "Campanha",
      value: "CAMPAIGN"
    },
    {
      label: "Ipb",
      value: "IPB"
    }
  ];

  searchStreamCampaign$ = new BehaviorSubject('');
  searchStreamPreSimulationCampaign$ = new BehaviorSubject('');
  searchStreamSimulationCampaign$ = new BehaviorSubject('');
  searchStreamCnae$ = new BehaviorSubject('');

  constructor(private formBuilder: FormBuilder,
    private configurationSimulatorService: ConfigurationSimulatorService,
    private priceCampaignService: PriceCampaignService,
    private cnaeService: CnaeService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private hierarchyService: HierarchyService,
    private dialogService: DialogService,
    private dialog: MatDialog) {
      this.icons = {
        faInfoCircle
      }
     }

  async ngOnInit() {
    this.loadingService.show();
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.ipbFormGroup = this.createIpbFormGroup();
    this.ipbFields = this.createIpbFields();

    await this.loadInstitutions();
    await this.loadServiceContracts(this.institutionCaixa);
    this.loadIpbConfiguration();

    this.loadingService.hide();
  }

  saveIpb(): void {
    this.loadingService.show();

    const data: ConfigurationSimulatorIpbModel = {
      institution: this.formControls.institution.value,

      serviceContract: this.formControls.serviceContract.value,

      pricingUrl: this.ipbFormControls.pricingUrl.value,

      massiveUrl: this.ipbFormControls.massiveUrl.value,

      applicationName: this.ipbFormControls.applicationName.value,

      ipbToken: this.ipbFormControls.ipbToken.value,

      contentType: this.ipbFormControls.contentType.value,

      requestWith: this.ipbFormControls.requestWith.value


    };

    this.configurationSimulatorService.setOrUpdateIpbConfiguration(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }
  save(): void {
    this.loadingService.show();

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if(!this.formControls.originPreSimulationRate.value && this.formControls.originPreSimulationCampaign.value.value == null){
      this.dialogService.openDialogWithMessage(ParamsSimulatorEnum.ORIGIN_PRE_SIMULATION_CAMPAIGN + ": " + Messages.REQUIRED_CONDITIONAL_FIELD_ERROR.description);
      return;
    }

    if(!this.formControls.originSimulationRate.value && this.formControls.originSimulationCampaign.value.value == null){
      this.dialogService.openDialogWithMessage(ParamsSimulatorEnum.ORIGIN_SIMULATION_CAMPAIGN + ": " + Messages.REQUIRED_CONDITIONAL_FIELD_ERROR.description);
      return;
    }

    const data: ConfigurationSimulatorModel = {
      institution: this.formControls.institution.value,

      serviceContract: this.formControls.serviceContract.value,

      ableEconomicGroup: this.formControls.ableEconomicGroup.value,

      originPreSimulationRate: this.formControls.originPreSimulationRate.value ? this.originRateOptions[1].value : this.originRateOptions[0].value,

      originPreSimulationCampaign: this.formControls.originPreSimulationRate.value ? ParamsSimulatorEnum.NO_CONTENT : this.formControls.originPreSimulationCampaign.value.value,

      originSimulationRate: this.formControls.originSimulationRate.value ? this.originRateOptions[1].value : this.originRateOptions[0].value,

      originSimulationCampaign: this.formControls.originSimulationRate.value ? ParamsSimulatorEnum.NO_CONTENT : this.formControls.originSimulationCampaign.value.value,

      exceptionCampaigns: this.formControls.exceptionCampaigns.value.map(campaign => campaign.value),

      exceptionCnaes: this.formControls.exceptionCnaes.value.map(cnae => cnae.value.cnae.toString()),

      ableHTMLEmail: this.formControls.ableHTMLEmail.value
    };

    this.configurationSimulatorService.setOrUpdateConfiguration(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  openHistory(institution: string, serviceContract: number) {
    const dialogRef = this.dialog.open(ConfigurationSimulatorHistoryComponent, {
      width: '100%',
      height: '100%',
      data: { institution, serviceContract }
    });
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required],
      ableEconomicGroup: ['', Validators.required],
      originPreSimulationRate: ['', Validators.required],
      originPreSimulationCampaign: [''],
      originSimulationRate: ['', Validators.required],
      originSimulationCampaign: [''],
      exceptionCampaigns: [''],
      exceptionCnaes: [''],
      ableHTMLEmail: ['', Validators.required]
    });
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        disabled: true,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Instituição inválida'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        showValueOnPlaceholder: true,
        controlName: 'serviceContract',
        disabled: true,
        messages: {
          required: 'Informe um service contract'
        }
      },
      ableEconomicGroup: {
        label: ParamsSimulatorEnum.ABLE_ECONOMIC_GROUP,
        items: this.booleanOptions,
        controlName: 'ableEconomicGroup',
        messages: {
          required: 'Informe uma opção'
        }
      },
      originPreSimulationRate: {
        label: ParamsSimulatorEnum.ORIGIN_PRE_SIMULATION_RATE,
        items: this.originRateOptions,
        controlName: 'originPreSimulationRate',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      originPreSimulationCampaign: {
        label: ParamsSimulatorEnum.ORIGIN_PRE_SIMULATION_CAMPAIGN,
        items: [],
        controlName: 'originPreSimulationCampaign',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      originSimulationRate: {
        label: ParamsSimulatorEnum.ORIGIN_SIMULATION_RATE,
        items: this.originRateOptions,
        controlName: 'originSimulationRate',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      originSimulationCampaign: {
        label: ParamsSimulatorEnum.ORIGIN_SIMULATION_CAMPAIGN,
        items: [],
        controlName: 'originSimulationCampaign',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      exceptionCampaigns: {
        label: ParamsSimulatorEnum.EXCEPTION_CAMPAIGNS,
        items: [],
        controlName: 'exceptionCampaigns',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      exceptionCnaes: {
        label: ParamsSimulatorEnum.EXCEPTION_CNAES,
        items: [],
        controlName: 'exceptionCnaes',
        messages: {
          required: 'Selecione uma opção'
        }
      },
      ableHTMLEmail: {
        label: ParamsSimulatorEnum.ABLE_HTML_EMAIL,
        items: this.booleanOptions,
        controlName: 'ableHTMLEmail',
        messages: {
          required: 'Informe uma opção'
        }
      }
    };
  }

  createIpbFormGroup(): FormGroup {
    return this.formBuilder.group({
      pricingUrl: [''],
      massiveUrl: [''],
      applicationName: [''],
      ipbToken: [''],
      contentType: [''],
      requestWith: ['']
    });

  }

  createIpbFields(): FdFieldConfigs {
    return {
      pricingUrl: {
        label: 'URL da API de precificação',
        controlName: 'pricingUrl'
     },
      massiveUrl: {
        label: 'URL da API de massiva',
        controlName: 'massiveUrl'
     },
      applicationName: {
        label: 'Nome da aplicação utilizado pelo IPB',
        controlName: 'applicationName'
     },
      ipbToken: {
        label: 'Token de autorização da API do IPB',
        controlName: 'ipbToken'
     },
     contentType: {
        label: 'Content-type',
        controlName: 'contentType'
     },
     requestWith: {
        label: 'X-Request-With',
        controlName: 'requestWith'
     },
    }
  }

  async loadInstitutions() {
   (this.fields.serviceContract as FdSelectConfig).items = [];
    this.formControls.serviceContract.disable();
    this.formControls.serviceContract.setValue('');

    await this.hierarchyService.institutionPromise()
    .then(list => {
      if (list != null) {
        (this.fields.institution as FdSelectConfig).items = [];
        (this.fields.institution as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
        (this.fields.institution as FdSelectConfig).items.push(...list);
        this.formControls.institution.setValue(this.institutionCaixa);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
        return;
      }
      this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
    });
  }

  async loadServiceContracts(institutionNumber: string) {
    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
      (this.fields.serviceContract as FdSelectConfig).items = [];
      this.formControls.serviceContract.disable();
      this.formControls.serviceContract.setValue('');
      return;
    }

    await this.hierarchyService.serviceContractByInstitutionPromise(institutionNumber)
      .then(data => {
        if (data) {
          this.formControls.serviceContract.setValue('');
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formControls.serviceContract.setValue(this.serviceContractCaixa);
        }
      }, (error: HttpErrorResponse) => {
        this.formControls.serviceContract.disable();
        this.formControls.serviceContract.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
      await this.searchServiceContractConfiguration();
  }

  loadIpbConfiguration() {
    if (this.formControls.institution.value && this.formControls.serviceContract.value) {

      this.configurationSimulatorService
        .getIpbConfiguration(this.formControls.institution.value, this.formControls.serviceContract.value)
        .subscribe(response => {
          if (response) {
            this.setIpbFieldValues(response);
          }
        }, (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.dialogService.openDialog(Messages.DATA_REQUIRED_SEARCH_ERROR);
            return;
          }
        });
    }

  }

  async searchServiceContractConfiguration() {
    if (this.formControls.institution.value && this.formControls.serviceContract.value) {

      await this.configurationSimulatorService
        .getConfigurationPromise(this.formControls.institution.value, this.formControls.serviceContract.value)
        .then(response => {
          if (response) {
            this.setFieldValues(response);
            this.loadCampaigns(this.formControls.institution.value, this.formControls.serviceContract.value, null, response);
            this.loadCnaes(this.formControls.institution.value, this.formControls.serviceContract.value, response);
          }
        }, (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.dialogService.openDialog(Messages.DATA_REQUIRED_SEARCH_ERROR);
            return;
          }
          this.setFieldValues(null);
          this.loadCampaigns(this.formControls.institution.value, this.formControls.serviceContract.value, err, null);
          this.loadCnaes(this.formControls.institution.value, this.formControls.serviceContract.value, null);
        });
    }
  }

  loadCampaigns(institution: string, serviceContract: number, errorBefore: HttpErrorResponse, response: ConfigurationSimulatorModel) {
    this.campaigns = [];
    this.priceCampaignService.campaignsByServiceContractAndStatus(institution, serviceContract, true, false)
      .subscribe(list => {
        if (list) {
          this.campaigns = list
            .map(value => ({
              value: value.idCampaign.toString(),
              label: `${value.idCampaign} - ${value.description}`
            }));

          (this.fields.exceptionCampaigns as FdSelectConfig).items = [];
          (this.fields.exceptionCampaigns as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.exceptionCampaigns as FdSelectConfig).items.push(...this.campaigns);
          this.selectedCampaigns = this.campaigns;
          this.selectedPreSimulationCampaigns = this.campaigns;
          this.selectedSimulationCampaigns = this.campaigns;

          this.formControls.exceptionCampaigns.setValue(this.setSelectedCampaign(response?.exceptionCampaigns));

        }
        if(errorBefore) {
          this.errorService.handleXHRError(errorBefore, Messages.GENERAL_ERROR);
        }
      },
        err => {
          if (err.status === 404) {
            this.dialogService.openDialog(Messages.DATA_REQUIRED_SEARCH_ERROR);
            return;
          }
          this.errorService.handleXHRError(err, Messages.SEARCH_NOT_FOUND);
        });
  }

  loadCnaes(institution: string, serviceContract: number, response: ConfigurationSimulatorModel) {
    this.cnaeService.getCnaeListByInstitutionAndServiceContract(institution, serviceContract)
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaes = cnaeList;

          (this.fields.exceptionCnaes as FdSelectConfig).items = [];
          (this.fields.exceptionCnaes as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.exceptionCnaes as FdSelectConfig).items.push(...this.cnaes);
          this.selectedCnaes = this.cnaes;

          this.formControls.exceptionCnaes.setValue(this.setSelectedCnae(response?.exceptionCnaes));
        }
      }, err => {
        if (err.status === 404) {
          this.dialogService.openDialog(Messages.DATA_REQUIRED_SEARCH_ERROR);
          return;
        }
        this.errorService.handleXHRError(err, Messages.SEARCH_NOT_FOUND);
      });
  }

  setFieldValues(response: ConfigurationSimulatorModel) {

    if (response) {
      this.formControls.ableEconomicGroup.setValue(response.ableEconomicGroup ? response.ableEconomicGroup : false);
      this.formControls.ableHTMLEmail.setValue(response.ableHTMLEmail ? response.ableHTMLEmail : false);
      this.formControls.originPreSimulationRate.setValue(this.setSelectedOrigin(response.originPreSimulationRate));
      this.formControls.originSimulationRate.setValue(this.setSelectedOrigin(response.originSimulationRate));
      this.formControls.originPreSimulationCampaign.setValue(this.setSelectedOriginCampaign(response?.originPreSimulationCampaign));
      this.formControls.originSimulationCampaign.setValue(this.setSelectedOriginCampaign(response?.originSimulationCampaign));
    }
    else {
      this.formControls.ableEconomicGroup.setValue(false);
      this.formControls.originPreSimulationRate.setValue(false);
      this.formControls.originSimulationRate.setValue(false);
      this.formControls.originPreSimulationCampaign.setValue([]);
      this.formControls.originSimulationCampaign.setValue([]);
    }
  }
  setIpbFieldValues(response: ConfigurationSimulatorIpbModel) {
    this.ipbFormControls.pricingUrl.setValue(response.pricingUrl);
    this.ipbFormControls.massiveUrl.setValue(response.massiveUrl);
    this.ipbFormControls.applicationName.setValue(response.applicationName);
    this.ipbFormControls.ipbToken.setValue(response.ipbToken);
    this.ipbFormControls.contentType.setValue(response.contentType);
    this.ipbFormControls.requestWith.setValue(response.requestWith);
  }

  setSelectedOrigin(data: string): boolean {
    return data === this.originRateOptions[0].value || data === '' || data === null ? false : true;
  }

  setSelectedCampaign(multipleData: string[]): Item[] {
    return this.campaigns.filter(campaign => multipleData?.includes(campaign.value));
  }

  setSelectedOriginCampaign(singleData: string): Item[] {
    return this.campaigns.filter(campaign => singleData?.includes(campaign.value));
  }

  setSelectedCnae(data: string[]): Item[] {
    return this.cnaes.filter(cnae => data?.includes(cnae.value.cnae.toString()));
  }

  get formControls() {
    return this.formGroup.controls;
  }

  get ipbFormControls() {
    return this.ipbFormGroup.controls;
  }


  get displayLabelCampaigns() {
    return this.formControls.exceptionCampaigns.value == 0;
  }

  get displayLabelCnaes() {
    return this.formControls.exceptionCnaes.value == 0;
  }

  @ViewChild('tooltip') tooltip: MatTooltip;
  showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  getTooltipTextAbleEconomicGroup() {
    return 'Habilita funcionalidade de Grupo Econômico no simulador de taxas.';
  }

  getTooltipTextOriginPreSimulationRate() {
    return 'Determina a origem das taxas da pré-simulação na jornada de grupo econômico.';
  }

  getTooltipTextOriginPreSimulationCampaign() {
    return 'Determina a campanha da pré-simulação na jornada de grupo econômico.';
  }

  getTooltipTextAbleHTMLEmail(){
    return 'Habilita conteúdo HTML no email de precificação.'
  }

  getTooltipTextOriginSimulationRate() {
    return 'Determina a origem das taxas da simulação na jornada de grupo econômico.';
  }

  getTooltipTextOriginSimulationCampaign() {
    return 'Determina a campanha da simulação na jornada de grupo econômico.';
  }

  getTooltipTextExceptionCampaign() {
    return 'Seleção de campanhas que não serão exibidas no simulador de taxas.';
  }

  getTooltipTextExceptionCnae() {
    return 'Seleção de cnae`s que não serão exibidos na jornada de pré-simulação de grupo econômico.';
  }

  obsCampaign$ = this.searchStreamCampaign$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap((query) =>
      concat(
        of({ type: 'start'}),
        this.getByFilterCampaign(query).pipe(map(value => ({ type: 'finish', value })))
      )) )

  getByFilterCampaign(query: string) {

    this.selectedCampaigns = this.campaigns.filter(c => c.label.toLowerCase().includes(query.toLowerCase()))

    if(this.formControls.exceptionCampaigns.value.length > 0){

      this.formControls.exceptionCampaigns.value.forEach(element => {
        if(this.selectedCampaigns.filter(item => item === element).length == 0) {
          this.selectedCampaigns.push(element);
        }
      });

    }

    return of(this.selectedCampaigns.values).pipe(delay(500));
  }

  obsPreSimulationCampaign$ = this.searchStreamPreSimulationCampaign$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap((query) =>
      concat(
        of({ type: 'start'}),
        this.getByFilterPreSimulationCampaign(query).pipe(map(value => ({ type: 'finish', value })))
      )) )

  getByFilterPreSimulationCampaign(query: string) {
    this.selectedPreSimulationCampaigns = this.campaigns.filter(c => c.label.toLowerCase().includes(query.toLowerCase()))
    return of(this.selectedPreSimulationCampaigns.values).pipe(delay(500));
  }

  obsSimulationCampaign$ = this.searchStreamSimulationCampaign$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    switchMap((query) =>
      concat(
        of({ type: 'start'}),
        this.getByFilterSimulationCampaign(query).pipe(map(value => ({ type: 'finish', value })))
      )) )

    getByFilterSimulationCampaign(query: string) {
    this.selectedSimulationCampaigns = this.campaigns.filter(c => c.label.toLowerCase().includes(query.toLowerCase()))
    return of(this.selectedSimulationCampaigns.values).pipe(delay(500));
  }

  obsCnae$ = this.searchStreamCnae$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((query) =>
      concat(
        of({ type: 'start'}),
        this.getByFilterCnae(query).pipe(map(value => ({ type: 'finish', value })))
      )) )

    getByFilterCnae(query: string) {
    this.selectedCnaes = this.cnaes.filter(c => c.label.toLowerCase().includes(query.toLowerCase()))

    if(this.formControls.exceptionCnaes.value.length > 0){

      this.formControls.exceptionCnaes.value.forEach(element => {
        if(this.selectedCnaes.filter(item => item === element).length == 0) {
          this.selectedCnaes.push(element);
        }
      });

    }

    return of(this.selectedCnaes.values).pipe(delay(500));
  }

}
