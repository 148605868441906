import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Subject } from 'rxjs';
import { ServicesTypePostModel } from 'src/app/shared/models/service-type-post.model';
import { ServicesConfigService } from '../../services/services-config.service';
import { ServicesTypeModel } from 'src/app/shared/models/services-type.model';
import { ModalDefinitions, FdAlertComponent } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { CurrencyMask } from 'src/app/shared/masks/currency-mask';
import { Messages } from 'src/app/shared/messages/messages';
import { RequiredIfValidator } from 'src/app/shared/validators/required-if-validator';
import { DialogService } from 'src/app/shared/service/dialog.service';


@Component({
  selector: 'app-options-service',
  templateUrl: './options-service.component.html',
  styleUrls: ['./options-service.component.scss']
})
export class OptionsServiceComponent implements OnInit {

  loading = false;
  terminalTypeSelected: string;
  isVenda = false;
  tooglemessage: string;

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  isFieldsLoaded: Subject<boolean> = new Subject();
  typeList = [
    {
      label: 'Aluguel',
      value: 'RENT'
    }, {
      label: 'Venda',
      value: 'SALES'
    },
    {
      label: 'Novo Comodato',
      value: 'NEW_LEND'
    }];

  constructor(
    public dialogRef: MatDialogRef<OptionsServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      servicesTypeModel: ServicesTypeModel
    },
    private servicesConfigService: ServicesConfigService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private dialog: MatDialog,

  ) { }


  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    (this.fields.type as FdSelectConfig).items = [];
    (this.fields.type as FdSelectConfig).items.push(...this.typeList);

    this.getfieldsSelected(this.data.servicesTypeModel);

  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      institutionNumber: ['', Validators.required],
      seriviceContract: ['', Validators.required],
      serviceID: ['', Validators.required],
      typeTerminal: ['', Validators.required],
      name: ['', Validators.required],
      type: ['', Validators.required],
      producer: ['', RequiredIfValidator(() => this.isVenda)],
      status: ['']
    });
  }

  createFields(): FdFieldConfigs {
    return {
      institutionNumber: {
        label: 'Número da Instituição',
        disabled: true,
        items: [],
        controlName: 'institutionNumber',
        messages: {
          required: 'Informe o número da instituição',
          invalid: 'Número da instituição inválido'
        }
      },
      seriviceContract: {
        label: 'Service Contract',
        disabled: true,
        items: [],
        controlName: 'seriviceContract',
        messages: {
          required: 'informe o serivice contract',
          invalid: 'Serivice contract inválido'
        }
      },
      serviceID: {
        label: 'service ID',
        disabled: true,
        items: [],
        controlName: 'serviceID',
        messages: {
          required: 'informe o service ID',
          invalid: 'Serivice service ID'
        }
      },
      typeTerminal: {
        label: 'Tipo de Terminal',
        disabled: true,
        items: [],
        controlName: 'typeTerminal',
        messages: {
          required: 'informe o Tipo de Terminal',
          invalid: 'Tipo de Terminal inválido'
        }
      },
      name: {
        label: 'Nome Comercial da Tecnologia',
        disabled: false,
        items: [],
        controlName: 'name',
        messages: {
          required: 'informe o Nome Comercial da Tecnologia',
          invalid: 'Nome Comercial da Tecnologia inválido'
        }
      },
      type: {
        label: 'Tipo de tecnologia',
        disabled: false,
        items: [],
        controlName: 'type',
        messages: {
          required: 'Selecione o tipo de tecnologia',
          invalid: 'Valor selecionado inválido'
        }
      },
      producer: {
        label: 'Fabricante',
        disabled: false,
        items: [],
        controlName: 'producer',
        messages: {
          required: 'Selecione um fabricante',
          invalid: 'Fabricante inválido'
        }
      },
      status: {
        label: 'Status',
        disabled: false,
        controlName: 'status',
        messages: {
          invalid: 'Status inválido'
        }
      }
    };
  }

  save(): void {

    if(!this.formGroup.valid){
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    let ServicesType = new ServicesTypePostModel();

    ServicesType.serviceId = Number(this.data.servicesTypeModel.serviceId);
    ServicesType.serviceContractId = this.data.servicesTypeModel.serviceContractId;
    ServicesType.modality = this.formGroup.value.type;
    ServicesType.tradeName = this.formGroup.value.name;
    ServicesType.institutionId = this.data.servicesTypeModel.institutionId;
    ServicesType.terminalManufacturerId = this.formGroup.value.producer;
    ServicesType.isEnabled = this.formGroup.value.status;

    if (
      (ServicesType.modality === 'SALES' && (!ServicesType.tradeName)) ||
      (ServicesType.modality === 'NEW_LEND' && (!ServicesType.tradeName)) ||
      (ServicesType.modality === 'RENT' && !ServicesType.tradeName) || !ServicesType.modality
    ) {
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_ERROR
      });

      return;
    }

    this.servicesConfigService.updateServiceConfig(ServicesType).subscribe(
      () => {


        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS,
        });


      },
      error => {

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_ERROR
        });
      }
    );

    this.dialogRef.close();
  }

  getfieldsSelected(fields: ServicesTypeModel): void {

    this.formGroup.controls.institutionNumber.setValue(fields.institutionId);

    this.formGroup.controls.seriviceContract.setValue(fields.serviceContractId);

    this.formGroup.controls.serviceID.setValue(fields.serviceId);

    this.formGroup.controls.typeTerminal.setValue(fields.serviceName);

    this.formGroup.controls.name.setValue(fields.tradeName);

    this.formGroup.controls.type.setValue(this.checkModality(fields.modality));

    this.formGroup.controls.status.setValue(fields.isEnabled);

    this.checkToggle(fields.isEnabled);

    this.checkType();
  }

  getTerminalManutacturer() {

    this.servicesConfigService.terminalManutacturer()
      .subscribe(list => {
        if (list != null) {
          (this.fields.producer as FdSelectConfig).items = [];
          (this.fields.producer as FdSelectConfig).items.push(...list);

          if (this.data.servicesTypeModel.terminalManufacturerId) {
            this.formGroup.controls.producer.setValue(this.data.servicesTypeModel.terminalManufacturerId.toString());
          }
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  checkType() {
    if (this.formGroup.value.type === 'SALES' || this.formGroup.value.type === 'NEW_LEND') {
      this.isVenda = true;
      this.getTerminalManutacturer();
    } else {
      this.isVenda = false;
      this.formGroup.controls.producer.setValue('');
    }
  }

  checkToggle(checked: boolean) {
    if (checked) {
      this.tooglemessage = 'Ativo';
    } else {
      this.tooglemessage = 'Inativo';
    }
  }

  toggleChange(event: any) {
    if (event.checked) {
      this.tooglemessage = 'Ativo';
    } else {
      this.tooglemessage = 'Inativo';
    }
  }

  checkModality(modality: string): string {
    switch (modality) {
      case 'Venda': {
        return 'SALES';
        break;
      }
      case 'Aluguel': {
        return 'RENT';
        break;
      }
      case 'Novo Comodato': {
        return 'NEW_LEND';
        break;
      }
      default: {
        return null;
        break;
      }
    }
  }

}
