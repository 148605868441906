<h3 fxLayoutAlign="center">Novo registro</h3>
<br />
<form [formGroup]="formGroup">
    <div fxLayoutAlign="center">
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center" class="filter-container">
            <div >
                <fd-input [parentForm]="formGroup" [field]="fields.resellerCode"></fd-input>
            </div>
            <div >
                <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                                [field]="fields.institution">
                </fd-institution>
            </div>
            <div >
                <fd-service-contract (serviceContractSelected)="changeSelectedServiceContract($event)" [formGroup]="formGroup" [field]="fields.serviceContract"
                [institution]="selectedInstitution">
                </fd-service-contract>
            </div>
            <div >
                <fd-select (selectionChange)="loadChannels()" [parentForm]="formGroup" [field]="fields.channelType" ></fd-select>
            </div>               
            <div >
                <fd-select [parentForm]="formGroup" [field]="fields.channel" ></fd-select>
            </div>

            <div mat-dialog-actions>
                <div class="value-edit-table__button-container">
                    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
                    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()">
                    </fd-button>
                </div>
            </div>
        </div>
    </div>
</form>    